import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
    divider: {
        width: '100%',
        margin: theme.spacing(1, 0),
    },
    contentRow: {
        flexBasis: 'auto',
        height: '100%',
    },
    emailInput: {
        paddingBottom: theme.spacing(),
    },
    emailChip: {
        backgroundColor: theme.palette.background.paper,
    },
    buttonSpacing: {
        marginRight: '10px',
    },
    buttonWidth: {
        width: '-webkit-fill-available',
    },
    gridItemSpacing: {
        marginTop: theme.spacing(2),
    },
    productList: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
    },
    productCheckbox: {
        paddingTop: '3px',
        paddingBottom: '3px',
    },
    checkboxLabel: {
        userSelect: 'none',
    },
}));
