import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { trackError } from '@local/metrics/dist/src/metrics';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Button from '@mui/material/Button/Button';
import Grid from '@mui/material/Grid/Grid';
import Tooltip from '@mui/material/Tooltip';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { useCustomUpsertFileByPathMutation } from 'src/apiClients/customFileEndpoints';
import UploadIcon from 'src/assets/UploadIcon';
import { usePermissionsContext } from 'src/contexts/PermissionsContext';
import { useFileUploadContainer } from 'src/hooks/useFileUploadContainer';
import { ERROR_UPLOADING_FILE, INSUFFICIENT_WORKSPACE_PERMISSION } from 'src/strings';
import { FileStatus, UploadStatus } from 'src/types/files';
import { hasRoleOrHigher } from 'src/utils/permissions';

import { useStyles } from './UploadFilesButton.styles';

export const UploadFilesButton = ({
    buttonText,
    disabled,
    secondary = false,
}: {
    buttonText: string;
    disabled?: boolean;
    secondary?: boolean;
}) => {
    const params = useParams();
    const organisationId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);
    const { classes } = useStyles();
    const { addMessage } = useMessagesContext();
    const [updateFile] = useCustomUpsertFileByPathMutation();
    const { upsertFileStatus } = useFileUploadContainer({ workspaceId });
    const uploadFieldRef = useRef<HTMLInputElement>(null);
    const { workspaceUserRole } = usePermissionsContext();

    const isViewer = workspaceUserRole ? !hasRoleOrHigher(workspaceUserRole, 'editor') : false;
    const isDisabled = disabled || isViewer;

    const handleUploadFile = async (file: File, currentFileStatus: FileStatus) => {
        const response = await updateFile({
            workspaceId,
            organisationId,
            filePath: `${file.name}`,
            uploadFile: file,
        });
        upsertFileStatus({
            ...currentFileStatus,
            uploadStatus: 'error' in response ? UploadStatus.Failed : UploadStatus.Uploaded,
            percentCompleted: 100,
        });
        return response;
    };

    const handleError = async (error: unknown, file: File, currentFileStatus: FileStatus) => {
        upsertFileStatus({
            ...currentFileStatus,
            uploadStatus: UploadStatus.Failed,
        });
        addMessage({
            message: ERROR_UPLOADING_FILE,
            type: NotificationType.ERROR,
        });
        trackError(`Error: ${error} uploading file "${file.name}"`);
        return Promise.reject(error);
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentFiles = event.currentTarget.files;
        if (!currentFiles) {
            return;
        }

        const uploadPromises = Array.from(currentFiles).map(async (file) => {
            const fileId = uuid();
            const currentFileStatus: FileStatus = {
                fileName: file.name,
                size: file.size,
                uploadStatus: UploadStatus.Uploading,
                fileId,
                percentCompleted: 0,
            };

            upsertFileStatus(currentFileStatus);

            if (uploadFieldRef.current) {
                uploadFieldRef.current.value = '';
            }

            const response = await handleUploadFile(file, currentFileStatus);
            if ('error' in response) {
                return handleError(response.error, file, currentFileStatus);
            }
            return response;
        });

        await Promise.all(uploadPromises);
    };

    return (
        <Tooltip title={isViewer ? INSUFFICIENT_WORKSPACE_PERMISSION : ''}>
            <span>
                <Button
                    disabled={isDisabled}
                    component="label"
                    color={secondary ? 'inherit' : 'primary'}
                    className={secondary ? classes.lightButton : undefined}
                    variant="contained"
                    automation-id="upload-files-button"
                    fullWidth
                >
                    <Grid container className={secondary ? classes.secondaryButton : ''}>
                        <Grid className={classes.buttonText}>
                            <UploadIcon fontSize="small" />
                            {buttonText}
                        </Grid>
                        <input
                            ref={uploadFieldRef}
                            type="file"
                            style={{ display: 'none' }}
                            multiple
                            onChange={handleFileChange}
                        />
                    </Grid>
                </Button>
            </span>
        </Tooltip>
    );
};
