import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import { Nullable, UpdateSnapshot } from '@local/webviz/dist/types/xyz';
import { UID_SUFFIXES } from '@local/webviz/dist/utilities';
import {
    ArrayClass,
    FilterClass,
    MappingClass,
    SceneClass,
    toSuffixUid,
} from '@local/webviz/dist/xyz';

import { AttributeTypes } from 'src/visualization/constants';
import { AttributeType } from 'src/visualization/types';

export const DEFAULT_GRADIENT = 'VIRIDIS';

export function generateAttributesSnapshot(
    attribute: Nullable<string>,
    attributeType: AttributeType,
): UpdateSnapshot {
    if (!attribute) {
        return {};
    }

    const attributeId = `attribute_${attribute}`;
    const dataId = toSuffixUid(attributeId, UID_SUFFIXES.DATA);
    const arrayId = toSuffixUid(attributeId, UID_SUFFIXES.ARRAY);
    const mappingId = toSuffixUid(attributeId, UID_SUFFIXES.MAPPING);
    const filterId = toSuffixUid(attributeId, UID_SUFFIXES.FILTER);

    const arraySnapshot = {
        [arrayId]: generateEntity(ArrayClass.PropertyTable, {
            propertyId: attributeId,
            id: arrayId,
        }),
    };
    let colorSnapshot = {};
    if (attributeType === AttributeTypes.Scalar) {
        colorSnapshot = {
            [mappingId]: generateEntity(MappingClass.Continuous, {
                gradient: DEFAULT_GRADIENT,
                data_control_values: [-Infinity, -Infinity, +Infinity, +Infinity],
                id: mappingId,
            }),
        };
    }

    const filterSnapshot = {
        [filterId]: generateEntity(FilterClass.Numeric, {
            id: filterId,
            min: -Infinity,
            max: +Infinity,
        }),
    };

    const dataSnapshot = {
        [dataId]: generateEntity(SceneClass.Data, {
            id: dataId,
            mapping: mappingId,
            array: arrayId,
            filter: filterId,
        }),
    };

    return {
        ...arraySnapshot,
        ...colorSnapshot,
        ...filterSnapshot,
        ...dataSnapshot,
    };
}
