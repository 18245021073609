import { gooseClient as api } from './gooseClient';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getObjectById: build.query<GetObjectResponse, GetObjectByIdApiArg>({
            query: (queryArg) => ({
                url: `/geoscience-object/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/objects/${queryArg.objectId}`,
                headers: { 'If-None-Match': queryArg['If-None-Match'] },
                params: { version: queryArg.version, include_versions: queryArg.includeVersions },
            }),
        }),
        listObjects: build.query<ListObjectsResponse, ListObjectsApiArg>({
            query: (queryArg) => ({
                url: `/geoscience-object/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/objects`,
                params: {
                    limit: queryArg.limit,
                    offset: queryArg.offset,
                    order_by: queryArg.orderBy,
                    author: queryArg.author,
                    created_at: queryArg.createdAt,
                    object_name: queryArg.objectName,
                    schema_id: queryArg.schemaId,
                },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as gooseClientEndpoints };

export type GetObjectByIdApiArg = {
    /** UUID of a geoscience object. */
    objectId: string;
    /** The customer organisation id. */
    orgId: string;
    workspaceId: string;
    /** The (timestamp) version of the object to retrieve. Defaults to the latest version if not specified. */
    version?: string;
    /** Determine whether to return all versions of the object */
    includeVersions?: boolean;
    /** Conditional request directive to fetch a specific version of the Geoscience object. */
    'If-None-Match'?: string;
};

export type ListObjectsApiArg = {
    /** The customer organisation id. */
    orgId: string;
    workspaceId: string;
    /** Max number of results per page. */
    limit?: number;
    /** Number of objects to skip before returning `limit` number of results */
    offset?: number;
    orderBy?: ('author' | 'created_at' | 'object_name') | string;
    /** (deprecated, use `created_by`) The profile ID that you want to filter by */
    author?: (string | string)[];
    /** A date or dates (max 2) to filter files by. Dates may contain operator prefixes, in the form '<operator>:<datetime>. The following operators are available ('lt'=less than, 'lte'=less than or equal to, 'gt'=greater than, 'gte'=greater than or equal to).If you omit the operator, then it is assumed the operator is 'equal to'. In this case you may only supply one date. The dates must also be in a valid ISO 8601 format.Dates may include a UTC offset. If the offset is omitted, the timezone is assumed to be UTC. */
    createdAt?: string[];
    /** The name of the object to filter on. Will perform a case sensitive prefix match, so the query `object_name=gold` will match an object with the name `goldcolumns.json */
    objectName?: string[];
    /** The type of schema you want to filter by */
    schemaId?: string[];
};
export type User = {
    email?: string | null;
    id: string;
    name?: string | null;
};
export type DataDownloadUrl = {
    download_url?: string | null;
    id: string;
};
export type ObjectResponseLinks = {
    data: DataDownloadUrl[];
    download: string;
};
export type GeoscienceObject = {
    schema: string;
    uuid: string;
    [key: string]: any;
};
export type ObjectVersionResponseLinks = {
    download: string;
};
export type GeoscienceObjectVersion = {
    created_at: string;
    created_by?: User | null;
    etag: string;
    links: ObjectVersionResponseLinks;
    version_id: string;
};
export type GetObjectResponse = {
    created_at: string;
    created_by?: User | null;
    etag: string;
    links: ObjectResponseLinks;
    object: GeoscienceObject;
    object_id: string;
    object_path?: string | null;
    version_id: string;
    versions?: GeoscienceObjectVersion[] | null;
};
export type HttpStatus =
    | 100
    | 101
    | 102
    | 103
    | 200
    | 201
    | 202
    | 203
    | 204
    | 205
    | 206
    | 207
    | 208
    | 226
    | 300
    | 301
    | 302
    | 303
    | 304
    | 305
    | 307
    | 308
    | 400
    | 401
    | 402
    | 403
    | 404
    | 405
    | 406
    | 407
    | 408
    | 409
    | 410
    | 411
    | 412
    | 413
    | 414
    | 415
    | 416
    | 417
    | 418
    | 421
    | 422
    | 423
    | 424
    | 425
    | 426
    | 428
    | 429
    | 431
    | 451
    | 500
    | 501
    | 502
    | 503
    | 504
    | 505
    | 506
    | 507
    | 508
    | 510
    | 511;
export type BadRequestError = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type MissingTokenError = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type InvalidTokenError = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type ObjectNotFound = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type EvoRequestValidationError = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type ApiError = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type BadGateway = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type ListObjectsResponseLinks = {
    next?: string | null;
    prev?: string | null;
};
export type ListedObjectLinks = {
    download: string;
};
export type ListedObject = {
    created_at: string;
    created_by?: User | null;
    etag: string;
    links: ListedObjectLinks;
    name: string;
    object_id: string;
    path: string;
    schema: string;
    version_id: string;
};
export type ListObjectsResponse = {
    count?: number | null;
    limit?: number | null;
    links: ListObjectsResponseLinks;
    objects: ListedObject[];
    offset?: number | null;
    total?: number | null;
};
export type InvalidFilterParams = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type ObjectStoreError = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export const {
    useGetObjectByIdQuery,
    useLazyGetObjectByIdQuery,
    useListObjectsQuery,
    useLazyListObjectsQuery,
} = injectedRtkApi;
