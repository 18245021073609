import { Filter } from '@local/workspaces/src/apiClients/workspaceClientEndpoints';
import queryString from 'query-string';
import { ArrayParam, NumberParam, StringParam, encodeQueryParams } from 'serialize-query-params';

import { filterByProperty } from 'src/apiClients/filterObjects';

import { ListObjectsApiArg, ListObjectsResponse } from './GENERATED_gooseClientEndpoints';
import { gooseClient } from './gooseClient';

const injectedRtkApi = gooseClient.injectEndpoints({
    endpoints: (build) => ({
        getObjectsByFilter: build.query<any, ListObjectsSchemaArg>({
            async queryFn(
                { orgId, folderSchema, workspaceId },
                _queryApi,
                _extraOptions,
                baseQuery,
            ) {
                const response = await baseQuery({
                    url: `/geoscience-object/orgs/${orgId}/workspaces/${workspaceId}/objects`,
                    method: 'GET',
                });

                if (response.error) {
                    return { error: response.error };
                }

                const data = response.data as ListObjectsResponse;
                const filteredResponse = filterByProperty(data, folderSchema);
                return { data: filteredResponse };
            },
        }),
        // custom endpoint for listObjects as the generated rtk endpoint doesn't support repeated key-value format for multi value parameters in query strings
        listObjectsFiltered: build.query<any, ListObjectsApiArg>({
            query: (queryArg) => {
                const baseUrl = `/geoscience-object/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/objects`;
                // ArrayParam allows array type parameters to be converted to repeated key-value pairs in the query string. i.e. param=[value1,value2] => "param=value1&param=value2"
                const listQueryString = queryString.stringify(
                    encodeQueryParams(
                        {
                            limit: NumberParam,
                            offset: NumberParam,
                            order_by: StringParam,
                            author: ArrayParam,
                            created_at: ArrayParam,
                            object_name: ArrayParam,
                            schema_id: ArrayParam,
                        },
                        {
                            limit: queryArg.limit,
                            offset: queryArg.offset,
                            order_by: queryArg.orderBy,
                            author: queryArg.author,
                            created_at: queryArg.createdAt,
                            object_name: queryArg.objectName,
                            schema_id: queryArg.schemaId,
                        },
                    ),
                );
                return `${baseUrl}?${listQueryString}`;
            },
        }),
    }),
});

export type ListObjectsSchemaArg = {
    orgId: string;
    workspaceId: string;
    folderSchema: Filter[];
};

export { injectedRtkApi as customGooseClientEndpoints };
