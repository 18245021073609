import { useLoadLicenseTokenQuery } from '@local/login/dist/apiClients/entitlementsClient/requests/EntitlementsAPI';
import { Spinner } from '@local/web-design-system/dist/components/Spinner';
import { Navigate } from 'react-router-dom';

/**
 * Route guard that ensures that the user has a valid licenses response,
 * this requires that there is already a valid list of ORGS, this should be wrapped
 * by the `OrgRouteGuard`
 */
export function LicenseGuard({ children }: { children: JSX.Element }) {
    const service = { service: 'evo' };
    const { isLoading, isError } = useLoadLicenseTokenQuery(service);

    if (isLoading) {
        return <Spinner automation-id="license-hub-guard" />;
    }

    if (isError) {
        return <Navigate to="/login?error=no_access_license" />;
    }

    // TODO tackle the restricted flow
    // if (restricted) {
    //     return RestrictedScreen();
    // }

    return children;
}
