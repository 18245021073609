import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { trackUserAction } from '@local/metrics/dist/src/metrics';
import { EvoServiceHub } from '@local/user-manage/dist';
import {
    useGetAdminsQuery,
    useGetUsersQuery,
    useGetAvailableEntitlementsQuery,
    useInviteUsersMutation,
} from '@local/user-manage/dist/apiClients/manageClient/enhancedUserManageMiddleware';
import { ProductSelections } from '@local/user-manage/dist/apiClients/manageClient/groupedManageEndpoints';
import {
    getInvitedUsers,
    getUsers,
} from '@local/user-manage/dist/components/GenericUserList/UserUtils';
import {
    MultiValueInput,
    Notification,
    NotificationType,
    Skeleton,
} from '@local/web-design-system/dist/components';
import { AddUserIcon } from '@local/web-design-system/dist/icons/User';
import {
    getHubForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { BackButton } from '@local/workspaces/dist/components/titleRow/buttons/BackButton';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PageContent } from '../../components/pageContent/PageContent';
import { UserContentContainer } from '../../components/userContentContainer/UserContentContainer';
import { Categories, UserAction } from '../../metrics.types';
import {
    BACK_TO_USERS,
    INVITE_ERROR_EXISTING_USERS,
    INVITE_ERROR_NOTIFICATION,
    INVITE_SUCCESS_NOTIFICATION,
    INVITE_USERS,
} from '../../strings';
import { USER_MANAGEMENT_PAGE } from '../../urls';
import { useStyles } from './InviteUsersPage.styles';
import { getProducts, availableAdminEntitlements } from './products';

const EMAIL_VALIDATION =
    // This is a slight modification of a regex that follows the RFC5322 standard, but with / # % { } ? & ! $ ` | removed
    // This is because emails are placed directly in the URL of requests, thus breaking with certain characters
    // tickets to change these endpoints to not do this are here: CENPLAT-6398 PS-5212
    // eslint-disable-next-line no-control-regex
    /^(?:[a-zA-Z0-9'*+=^_~-]+(?:\.[a-zA-Z0-9'*+=^_~-]+)*)@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:+)\])$/;

export const InviteUsersPage = () => {
    setDocumentTitle(INVITE_USERS);
    const { classes } = useStyles();
    const params = useParams();
    const navigate = useNavigate();
    const { evouiVarifyManagement, evouiDriverManagement } = useFlags();
    const { addMessage } = useMessagesContext();

    const [isValidAddresses, setIsValidAddresses] = useState(false);
    const [usersToInvite, setUsersToInvite] = useState<string[]>([]);
    const [isAdmin, setAdmin] = useState(false);
    const [productSelections, setProductSelections] = useState<ProductSelections>({});

    const evoResource: EvoServiceHub = {
        org_id: getOrgUuidFromParams(params),
        hub: getHubForCurrentOrg().code,
        service: 'evo',
    };
    // Users and admins are distinct resources
    const usersResult = useGetUsersQuery(evoResource);
    const adminUsersResult = useGetAdminsQuery(evoResource);

    // Get a list of all users and admins, invited or not, so we can prevent them from being added again
    const allUsers = useMemo(() => {
        const invited = getInvitedUsers(
            usersResult.data?.invited ?? [],
            adminUsersResult.data?.invited ?? [],
        );
        const users = getUsers(usersResult.data?.users ?? [], adminUsersResult.data?.admins ?? []);

        return [...invited, ...users];
    }, [usersResult.data, adminUsersResult.data]);

    const {
        data: availableEntitlementsData,
        isLoading: availableEntitlementsIsLoading,
        isSuccess: availableEntitlementsIsSuccess,
    } = useGetAvailableEntitlementsQuery({
        org_id: getOrgUuidFromParams(params),
        hub: getHubForCurrentOrg().code,
    });

    const disableAddUsersButton = usersToInvite.length === 0 && !isValidAddresses;

    const [inviteUsers, { isLoading: inviteUsersIsLoading }] = useInviteUsersMutation();

    const addUsers = async () => {
        // Check if any users have already been added
        const existingUsers = usersToInvite.filter((user) =>
            allUsers.some(
                (existingUser) => existingUser.email.toLowerCase() === user.toLowerCase(),
            ),
        );

        if (existingUsers.length) {
            addMessage({
                message: `${INVITE_ERROR_EXISTING_USERS}${existingUsers.join(', ')}`,
                type: NotificationType.ERROR,
            });
            return;
        }

        try {
            await inviteUsers({
                org_id: getOrgUuidFromParams(params),
                hub: getHubForCurrentOrg().code,
                isAdmin,
                entitlements: { evo: true, ...productSelections },
                adminEntitlements: [
                    'evo',
                    ...availableAdminEntitlements(
                        availableEntitlementsData?.availableEntitlements ?? [],
                    ),
                ],
                emails: usersToInvite,
            });
            addMessage({
                message: INVITE_SUCCESS_NOTIFICATION,
                type: NotificationType.SUCCESS,
            });
            trackUserAction(isAdmin ? UserAction.ADMIN_CREATED_ADMIN : UserAction.USER_INVITED, {
                category: Categories.EVO_USER_MANAGEMENT,
            });
            navigate(`/${params.orgUuid}/${USER_MANAGEMENT_PAGE}`);
        } catch {
            addMessage({
                message: INVITE_ERROR_NOTIFICATION,
                type: NotificationType.ERROR,
            });
        }
    };

    const availableProducts =
        availableEntitlementsIsSuccess && availableEntitlementsData
            ? getProducts(evouiDriverManagement, evouiVarifyManagement)
                  .filter((product) =>
                      availableEntitlementsData.availableEntitlements.includes(product.service),
                  )
                  .sort((a, b) => a.displayName.localeCompare(b.displayName))
            : [];

    // If a customer only has the Evo entitlement, and there are no additional entitlements available, we shouldn't
    // show the entitlement options.
    const shouldShowProductSelect =
        availableEntitlementsIsSuccess &&
        availableEntitlementsData &&
        availableEntitlementsData?.availableEntitlements.length > 1;

    return (
        <PageContent
            pageContentPadding={false}
            pageTitle={INVITE_USERS}
            breadcrumbSegments={[
                { name: 'Users', path: `/${params.orgUuid}/${USER_MANAGEMENT_PAGE}` },
                { name: INVITE_USERS },
            ]}
            titleActions={
                <BackButton
                    text={BACK_TO_USERS}
                    destination={`/${params.orgUuid}/${USER_MANAGEMENT_PAGE}`}
                />
            }
        >
            <UserContentContainer
                columnContent={
                    <>
                        <Grid item xs={12}>
                            <Typography color="primary" variant="h4">
                                Email Addresses
                            </Typography>
                            <Typography color="textSecondary" variant="body2">
                                Separate emails with commas
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <MultiValueInput
                                placeholder=""
                                onChange={(values) => setUsersToInvite(values)}
                                validation={EMAIL_VALIDATION}
                                currentValueValid={(valid: any) => setIsValidAddresses(valid)}
                                classes={{ chip: classes.emailChip, root: classes.emailInput }}
                            />
                        </Grid>
                        <Divider className={classes.divider} />

                        <Grid item xs={12}>
                            <Typography variant="h4">Roles</Typography>
                            <FormControlLabel
                                label={<Typography variant="body2">Admin</Typography>}
                                control={
                                    <Checkbox
                                        onChange={(event, checked) => setAdmin(checked)}
                                        value="checkedB"
                                        color="primary"
                                        className={classes.productCheckbox}
                                    />
                                }
                            />
                        </Grid>
                        {availableEntitlementsIsLoading ? (
                            <Grid
                                item
                                container
                                direction="column"
                                xs={12}
                                className={classes.productList}
                            >
                                <Skeleton variant="text" width="100%" height={35} />
                                <Skeleton variant="text" width="100%" height={35} />
                                <Skeleton variant="text" width="100%" height={35} />
                            </Grid>
                        ) : null}
                        {!shouldShowProductSelect ? null : (
                            <Grid
                                item
                                container
                                direction="column"
                                xs={12}
                                className={classes.productList}
                            >
                                <>
                                    <Typography variant="h4">Products</Typography>
                                    <FormControlLabel
                                        // adding Evo checkbox which should always be on and non interactive
                                        label={<Typography variant="body2">Evo</Typography>}
                                        className={classes.checkboxLabel}
                                        control={
                                            <Checkbox
                                                className={classes.productCheckbox}
                                                value="checkedB"
                                                color="primary"
                                                checked
                                                disabled
                                            />
                                        }
                                        automation-id="evo-cosmetic-checkbox"
                                    />
                                    {availableProducts.map((product) => (
                                        <FormControlLabel
                                            key={product.service}
                                            className={classes.checkboxLabel}
                                            control={
                                                <div>
                                                    <Checkbox
                                                        checked={
                                                            productSelections[product.service] ??
                                                            false
                                                        }
                                                        onChange={(_event, checked) =>
                                                            setProductSelections((prevState) => ({
                                                                ...prevState,
                                                                [product.service]: checked,
                                                            }))
                                                        }
                                                        className={classes.productCheckbox}
                                                        value={product.service}
                                                        color="primary"
                                                    />
                                                </div>
                                            }
                                            label={
                                                <Typography variant="body2">
                                                    {product.displayName}
                                                </Typography>
                                            }
                                        />
                                    ))}
                                </>
                            </Grid>
                        )}
                        <Divider className={classes.divider} />
                        <Grid item xs={12} className={classes.gridItemSpacing}>
                            <Notification
                                type={NotificationType.INFO_LIGHT}
                                message="Users without a Seequent  ID will receive an email with instructions on how to create one."
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.gridItemSpacing}>
                            {inviteUsersIsLoading ? (
                                <Skeleton variant="rectangle" width="100%" height="47.33px" />
                            ) : (
                                <Button
                                    disabled={disableAddUsersButton}
                                    className={classes.buttonWidth}
                                    color="primary"
                                    automation-id="title-row-action-button"
                                    variant="contained"
                                    size="small"
                                    onClick={addUsers}
                                >
                                    <div className={classes.buttonSpacing}>
                                        <AddUserIcon fontSize="small" />
                                    </div>
                                    {INVITE_USERS}
                                </Button>
                            )}
                        </Grid>
                    </>
                }
            />
        </PageContent>
    );
};
