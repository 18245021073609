import { DateRange } from '@local/web-design-system/dist/components/DateRangeCalendar/types';
import { useEffect, useState } from 'react';

import { filterTypeMetaData } from 'src/mockApi/mockFilterDataTypes';

import { usePersistedState } from './usePersistedState';

/**
 * Provides a custom hook for the object list filter parameters.
 * Applied filter parameters are stored in the url as query parameters.
 * Also stores the temporary state of the filter menu.
 */
export function useObjectFilterParams() {
    const [typeParamString, setTypeParamString] = usePersistedState('type');
    const [updatedByParamString, setUpdatedByParamString] = usePersistedState('updatedBy');
    const [updatedOnStartParamString, setUpdatedOnStartParamString] =
        usePersistedState('updateOnStart');
    const [updatedOnEndParamString, setUpdatedOnEndParamString] = usePersistedState('updatedOnEnd');

    const [typeParams, setTypeParams] = useState<string[]>([]);
    const [updatedByParams, setUpdatedByParams] = useState<string[]>([]);
    const [updatedOnParams, setUpdatedOnParams] = useState<DateRange>({});

    useEffect(() => {
        setTypeParams(typeParamString === '' ? [] : typeParamString?.split(',') ?? []);
    }, [typeParamString]);

    useEffect(() => {
        setUpdatedByParams(
            updatedByParamString === '' ? [] : updatedByParamString?.split(',') ?? [],
        );
    }, [updatedByParamString]);

    useEffect(() => {
        if (updatedOnStartParamString && updatedOnEndParamString) {
            setUpdatedOnParams({
                startDate: new Date(updatedOnStartParamString),
                endDate: new Date(updatedOnEndParamString),
            });
        } else {
            setUpdatedOnParams({});
        }
    }, [updatedOnStartParamString, updatedOnEndParamString]);

    const [type, setType] = useState<string[]>(typeParams);
    const [updatedBy, setUpdatedBy] = useState<string[]>(updatedByParams);
    const [updatedOn, setUpdatedOn] = useState<DateRange>(updatedOnParams);

    const reset = () => {
        setType(typeParams);
        setUpdatedBy(updatedByParams);
        setUpdatedOn(updatedOnParams);
    };

    const applyFilters = () => {
        setUpdatedByParamString(updatedBy.length === 0 ? undefined : updatedBy.join(','));
        setTypeParamString(type.length === 0 ? undefined : type.join(','));
        if (updatedOn.startDate && updatedOn.endDate) {
            setUpdatedOnStartParamString(updatedOn.startDate.toISOString());
            setUpdatedOnEndParamString(updatedOn.endDate.toISOString());
        } else if (!updatedOn.startDate && !updatedOn.endDate) {
            setUpdatedOnStartParamString(undefined);
            setUpdatedOnEndParamString(undefined);
        }
    };

    const clearAllParams = () => {
        setTypeParamString(undefined);
        setUpdatedByParamString(undefined);
        setUpdatedOnStartParamString(undefined);
        setUpdatedOnEndParamString(undefined);
        setType([]);
        setUpdatedBy([]);
        setUpdatedOn({});
    };

    const removeTypeParam = (typeParam: string) => {
        setTypeParamString(
            typeParams.length <= 1
                ? undefined
                : typeParams.filter((param) => param !== typeParam).join(','),
        );
    };

    const removeUpdatedByParam = (updatedByParam: string) => {
        setUpdatedByParamString(
            updatedByParams.length <= 1
                ? undefined
                : updatedByParams.filter((param) => param !== updatedByParam).join(','),
        );
    };

    const removeUpdatedOnParam = () => {
        setUpdatedOnStartParamString(undefined);
        setUpdatedOnEndParamString(undefined);
    };

    const cannotApplyFilters =
        typeParams.length === type.length &&
        typeParams.every((param) => type.includes(param)) &&
        updatedByParams.length === updatedBy.length &&
        updatedByParams.every((param) => updatedBy.includes(param)) &&
        updatedOnParams.startDate?.getTime() === updatedOn.startDate?.getTime() &&
        updatedOnParams.endDate?.getTime() === updatedOn.endDate?.getTime();

    const cannotClearFilters =
        type.length === 0 &&
        typeParams.length === 0 &&
        updatedBy.length === 0 &&
        updatedByParams.length === 0 &&
        !updatedOn.startDate &&
        !updatedOn.endDate &&
        !updatedOnParams.startDate &&
        !updatedOnParams.endDate;

    const nextDay = (date: Date) => {
        const newDate = new Date(date);
        newDate.setDate(date.getDate() + 1);
        return newDate;
    };

    const [filters, setFilters] = useState({});
    useEffect(() => {
        setFilters({
            schemaId:
                typeParams.length > 0
                    ? typeParams
                          .flatMap(
                              (param) =>
                                  filterTypeMetaData.options.find(
                                      (option) => option.description === param,
                                  )?.comparison || [],
                          )
                          .map((param) => `like:*${param}*`)
                    : undefined,
            author: updatedByParams.length > 0 ? updatedByParams : undefined,
            createdAt:
                updatedOnParams.startDate && updatedOnParams.endDate
                    ? [
                          `gte:${updatedOnParams.startDate.toISOString()}`,
                          `lte:${nextDay(updatedOnParams.endDate).toISOString()}`,
                      ]
                    : undefined,
        });
    }, [typeParams, updatedByParams, updatedOnParams]);

    return {
        typeParams,
        updatedByParams,
        updatedOnParams,
        removeTypeParam,
        removeUpdatedByParam,
        removeUpdatedOnParam,
        clearAllParams,
        type,
        setType,
        updatedBy,
        setUpdatedBy,
        updatedOn,
        setUpdatedOn,
        applyFilters,
        cannotApplyFilters,
        cannotClearFilters,
        reset,
        filters,
    };
}
