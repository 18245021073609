import { useBaseXyz } from '@local/webviz/dist/context';
import { Nullable, UpdateSnapshot } from '@local/webviz/dist/types';
import { UID_SUFFIXES } from '@local/webviz/dist/utilities';
import { ElementClass, toSuffixUid } from '@local/webviz/dist/xyz';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import isFinite from 'lodash-es/isFinite';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useLazyGetObjectByIdQuery } from 'src/apiClients/enhancedGooseMiddleware';
import { useAppDispatch } from 'src/store/store';
import { visualizationSlice } from 'src/store/visualization/visualizationSlice';
import { generateSnapshot } from 'src/visualization/context/snapshots/generateSnapshot';

const {
    actions: { addToLoadedObjects },
} = visualizationSlice;

export const ID = 'id';

function getIdByElementClass(
    snapshot: UpdateSnapshot,
    entityClass: ElementClass,
): Nullable<string> {
    const entry = Object.entries(snapshot).find(
        // eslint-disable-next-line no-underscore-dangle
        ([_key, value]) => value && value.__class__ === entityClass,
    );
    if (!entry) {
        return null;
    }
    return entry[0];
}

export function useObjectManager() {
    const dispatch = useAppDispatch();
    const { setStateFromSnapshot, addListener } = useBaseXyz();
    const [GetObjectByIdTrigger, { data: gooseObject }] = useLazyGetObjectByIdQuery();

    const params = useParams();
    const [searchParams] = useSearchParams();

    const [initialSnapshot, setInitialSnapshot] = useState<UpdateSnapshot>({});

    // TODO: remove objectId and folderId from query param,
    // this will be replaced with some form of state management as we don't want the url to contain any of this info
    const objectId = searchParams.get(ID) ?? '';

    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    useEffect(() => {
        if (objectId) {
            GetObjectByIdTrigger({
                objectId,
                orgId,
                workspaceId,
            });
        }
    }, [objectId]);

    useEffect(() => {
        if (gooseObject) {
            const snapshot = generateSnapshot(gooseObject, { objectId, workspaceId, orgId });
            if (!snapshot) {
                console.error(`Unsupported schema: ${gooseObject.object.schema}`);
                return;
            }
            setStateFromSnapshot(snapshot, {});
            setInitialSnapshot(snapshot);
            dispatch(addToLoadedObjects(gooseObject.object));
        }
    }, [gooseObject]);
    const listeners: (() => void)[] = [];
    useEffect(() => {
        // TODO: handle multiple Tileset3D elements in the same scene when applicable
        const elementId = getIdByElementClass(initialSnapshot, ElementClass.Tileset3D);
        if (!elementId) {
            return () => {};
        }

        const removeElementMetadataListener = addListener(
            elementId,
            'metadata',
            ({ attributes_metadata }) => {
                const attributeIds = Object.keys(attributes_metadata);
                if (attributeIds.length) {
                    const attributesSnapshot = attributeIds.reduce(
                        (accumulator: UpdateSnapshot, attributeId: string) => {
                            const { min, max } = attributes_metadata[attributeId].metadata;
                            const mappingId = toSuffixUid(attributeId, UID_SUFFIXES.MAPPING);
                            const minValue = isFinite(min) ? min : -Infinity;
                            const maxValue = isFinite(max) ? max : +Infinity;
                            return {
                                ...accumulator,
                                [mappingId]: {
                                    data_control_values: [minValue, minValue, maxValue, maxValue],
                                },
                            };
                        },
                        {},
                    );

                    setStateFromSnapshot(attributesSnapshot, {});
                }
            },
        );
        listeners.push(removeElementMetadataListener);

        return () => {
            listeners.forEach((removeListener) => {
                removeListener();
            });
        };
    }, [initialSnapshot]);
}
