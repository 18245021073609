import { getConfig } from '@local/app-config';
import { SidebarProvider } from '@local/content-area/dist/Sidebar/SidebarContext';
import { LandingPageProps, LoginHandler } from '@local/login';
import { setLoginError } from '@local/login/dist/store/sessionStorageHelpers/loginErrorHelper';
import { Messages } from '@local/messages/dist/Messages';
import { trackError } from '@local/metrics';
import { UnsupportedBrowserOverlay } from '@local/unsupported-browser/dist/UnsupportedBrowserOverlay';
import { OrgRouteGuard } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { workspaceHandler } from '@local/workspaces/dist/components/WorkspaceHandler';
import { WORKSPACES_LIST_PAGE } from '@local/workspaces/dist/urls';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Outlet, useLocation, useRoutes } from 'react-router-dom';

import { Visualization } from 'src/visualization/Visualization/Visualization';

import LogoTitle from './assets/logoTitle';
import ContentScaffolding from './components/contentScaffolding/ContentScaffolding';
import { LicenseGuard } from './components/licenseGuard/licenseGuard';
import { SearchParamsProvider } from './contexts/SearchParamsContext';
import { startWorker } from './mockApi/mock';
import { DiscoverPage } from './pages/discoverPage/DiscoverPage';
import { BentleyEditUserPage } from './pages/editUserPage/BentleyEditUserPage';
import { EditUserPage } from './pages/editUserPage/EditUserPage';
import { InviteUsersPage } from './pages/inviteUsersPage/InviteUsersPage';
import { LandingPage } from './pages/landingPage/LandingPage';
import { ListUsersPage } from './pages/listUsersPage/ListUsersPage';
import { GenericNotFoundPage } from './pages/notFoundPage/GenericNotFoundPage';
import { WorkspaceFilesPage } from './pages/workspaceFilesPage/WorkspaceFilesPage';
import { WorkspacePage } from './pages/workspacePage/WorkspacePage';
import { store } from './store/store';
import { LOG_IN_AGAIN, LOGIN_FAILED, NOT_FOUND_MESSAGE, NOT_FOUND_TITLE } from './strings';
import {
    DISCOVER_PAGE,
    USER_INVITE_PAGE,
    USER_MANAGEMENT_PAGE,
    WEBVIZ_PLOT,
    WORKSPACE_OVERVIEW,
    WORKSPACE_FILES,
} from './urls';

export function WrappedLandingPage({ getSignInUrl }: LandingPageProps) {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const error = query.get('error');

    if (error) {
        trackError('Error on login', 'error from login in <App>', {
            error,
            description: query.get('description') || undefined,
        });
        setLoginError(error ? `${LOGIN_FAILED} ${LOG_IN_AGAIN}` : '');
    }

    return <LandingPage getSignInUrl={getSignInUrl} />;
}

function ConditionalLicenseGuard({ children }: { children: JSX.Element }) {
    return getConfig().bentleyIdEnabled ? children : <LicenseGuard>{children}</LicenseGuard>;
}

export const useAdditionalScopes = () =>
    getConfig().bentleyIdEnabled
        ? ['openid', 'profile', 'evo.discovery', 'evo.workspace']
        : ['evo.discovery', 'evo.workspace', 'evo.file'];

export function EvoRoutes() {
    const {
        evouiEnableGooseRendering,
        evouiWorkspaceFiles,
        evouiHideLicenseInfo,
        evouiEnableDiscover,
    } = useFlags();
    const extraTabDefinitions = [{ key: 'overview', label: 'Overview', path: WORKSPACE_OVERVIEW }];

    if (evouiWorkspaceFiles) {
        extraTabDefinitions.push({
            key: 'files',
            label: 'Files',
            path: WORKSPACE_FILES,
        });
    }

    const routes = useRoutes([
        {
            path: '/',
            element: (
                <OrgRouteGuard>
                    <ConditionalLicenseGuard>
                        <SearchParamsProvider>
                            <SidebarProvider>
                                <Outlet />
                                <Messages queue="global" />
                                <UnsupportedBrowserOverlay />
                            </SidebarProvider>
                        </SearchParamsProvider>
                    </ConditionalLicenseGuard>
                </OrgRouteGuard>
            ),
            children: [
                {
                    path: ':orgUuid',
                    element: <ContentScaffolding />,
                    children: [
                        workspaceHandler({
                            appRoutes: (tabs: JSX.Element) => [
                                {
                                    path: WORKSPACE_OVERVIEW,
                                    element: <WorkspacePage tabs={tabs} />,
                                },
                                {
                                    ...(evouiWorkspaceFiles && {
                                        path: WORKSPACE_FILES,
                                        element: <WorkspaceFilesPage tabs={tabs} />,
                                    }),
                                },
                                {
                                    ...(evouiEnableGooseRendering && {
                                        path: WEBVIZ_PLOT,
                                        element: <Visualization />,
                                    }),
                                },
                            ],
                            canNavigateToWorkspace: true,
                            extraTabDefinitions,
                        }),
                        {
                            path: USER_MANAGEMENT_PAGE,
                            children: [
                                {
                                    index: true,
                                    element: <ListUsersPage />,
                                },
                                {
                                    ...(!evouiHideLicenseInfo && {
                                        path: USER_INVITE_PAGE,
                                        element: <InviteUsersPage />,
                                    }),
                                },
                                {
                                    path: ':userUuid',
                                    element: getConfig().bentleyIdEnabled ? (
                                        <BentleyEditUserPage />
                                    ) : (
                                        <EditUserPage />
                                    ),
                                },
                            ],
                        },
                        {
                            ...(evouiEnableDiscover && {
                                path: DISCOVER_PAGE,
                                children: [
                                    {
                                        index: true,
                                        element: <DiscoverPage />,
                                    },
                                ],
                            }),
                        },
                        {
                            path: '*',
                            element: (
                                <GenericNotFoundPage
                                    title={NOT_FOUND_TITLE}
                                    message={NOT_FOUND_MESSAGE}
                                />
                            ),
                        },
                    ],
                },
            ],
        },
    ]);
    return routes;
}

export const App = () => (
    <LoginHandler
        guardedRoutes={<EvoRoutes />}
        store={store}
        LandingPage={WrappedLandingPage}
        LandingPageLogo={LogoTitle}
        service="evo"
        homePagePath={WORKSPACES_LIST_PAGE}
        useOrgSelector
        additionalScopes={useAdditionalScopes()}
    />
);

// Exclude our msw mocks from running in our Jest tests.
if (typeof process !== 'object') {
    startWorker();
}
