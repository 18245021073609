import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { trackError, trackUserAction } from '@local/metrics';
import { EvoService, ManageUser } from '@local/user-manage/dist/apiClients/entities';
import { useUpdateUserMutation } from '@local/user-manage/dist/apiClients/manageClient/enhancedUserManageMiddleware';
import { ProductSelections } from '@local/user-manage/dist/apiClients/manageClient/groupedManageEndpoints';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import {
    getHubForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';

import UploadIcon from 'src/assets/UploadIcon';
import { Categories, UserAction } from 'src/metrics.types';
import { USER_SAVE, USER_UPDATE_FAIL, USER_UPDATE_SUCCESS } from 'src/strings';

import { useStyles } from './SaveUserButton.styles';

export interface SaveUserButtonProps {
    isAdmin: boolean;
    productSelections: ProductSelections;
    adminProducts: EvoService[];
    user: ManageUser;
}
export const SAVE_BUTTON_CACHE_KEY = 'saveUserButton';

export function SaveUserButton({
    isAdmin,
    user,
    productSelections,
    adminProducts,
}: SaveUserButtonProps) {
    const { classes } = useStyles();
    const { addMessage } = useMessagesContext();
    const params = useParams();
    const [triggerUpdateUser] = useUpdateUserMutation({
        fixedCacheKey: SAVE_BUTTON_CACHE_KEY,
    });

    const savePermissions = async () => {
        const orgId = getOrgUuidFromParams(params);
        const hub = getHubForCurrentOrg().code;

        try {
            await triggerUpdateUser({
                org_id: orgId,
                email: user.email,
                hub,
                isAdmin,
                adminEntitlements: ['evo', ...adminProducts],
                baseEntitlements: productSelections,
            });
            trackUserAction(UserAction.USER_ROLE_UPDATED, {
                category: Categories.EVO_USERS_PAGE,
            });
            addMessage(
                {
                    message: USER_UPDATE_SUCCESS,
                    type: NotificationType.SUCCESS,
                },
                { queue: 'edit-user' },
            );
        } catch {
            trackError('Error updating user roles from evo');
            addMessage(
                {
                    message: USER_UPDATE_FAIL,
                    type: NotificationType.ERROR,
                },
                { queue: 'edit-user' },
            );
        }
    };

    return (
        <Grid item xs={6}>
            <Button
                color="primary"
                variant="contained"
                size="large"
                className={classes.controlButton}
                onClick={() => {
                    savePermissions();
                }}
                automation-id="save-user"
            >
                <UploadIcon color="inherit" fontSize="small" />
                <Typography variant="body2" color="inherit" className={classes.buttonIconText}>
                    {USER_SAVE}
                </Typography>
            </Button>
        </Grid>
    );
}
