import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import {
    DEFAULT_FLAT_COLOR,
    LINE_DEFAULT_MODE,
    LINE_DEFAULT_WIDTH,
    POINTS_DEFAULT_MODE,
    POINTS_DEFAULT_SIZE,
} from '@local/webviz/dist/context/snapshots/defaults';
import { UpdateSnapshot } from '@local/webviz/dist/types/xyz';
import { UID_SUFFIXES } from '@local/webviz/dist/utilities';
import { ElementClass, ViewClass, toSuffixUid } from '@local/webviz/dist/xyz';

import { GetObjectResponse } from 'src/apiClients/GENERATED_gooseClientEndpoints';
import { getUrlConfig } from 'src/apiClients/utils';
import { extractSchema } from 'src/utils/extractSchema';
import { Schemas } from 'src/visualization/constants';

import { GeoscienceLineObject, GeosciencePointObject, TilesetEndpointParams } from '../../types';
import { generateAttributesSnapshot } from './attributeSnapshot';

export function generateSnapshot(
    gooseObject: GetObjectResponse,
    params?: TilesetEndpointParams,
): UpdateSnapshot | undefined {
    const schema = extractSchema(gooseObject.object.schema);
    switch (schema) {
        case Schemas.PointsetSchema: {
            const snapshot = generatePointsSnapshot(gooseObject as GeosciencePointObject);
            return snapshot;
        }
        case Schemas.DownholeIntervalsSchema: {
            if (!params) {
                return undefined;
            }
            const snapshot = generateTileset3DLinesSnapshot(
                gooseObject as GeoscienceLineObject,
                params,
            );
            return snapshot;
        }
        default:
            return undefined;
    }
}

function generatePointsSnapshot(gooseObject: GeosciencePointObject): UpdateSnapshot | undefined {
    const {
        object_id: objectId,
        object: {
            locations: {
                coordinates: { width, length, data_type: dataType, data: dataId },
            },
        },
        links: { data },
    } = gooseObject;

    const dataObject = data.find(({ id }) => id === dataId);

    if (!dataObject?.download_url || !objectId) {
        return undefined;
    }

    const viewId = toSuffixUid(objectId, ViewClass.Points);
    const elementId = toSuffixUid(objectId, ElementClass.Points);
    const snapshot = {
        [elementId]: generateEntity(ElementClass.Points, {
            verticesUrl: {
                location: dataObject.download_url,
                shape: [length, width],
                dataType,
            },
        }),
        [viewId]: generateEntity(ViewClass.Points, {
            element: elementId,
            color: DEFAULT_FLAT_COLOR,
            size: POINTS_DEFAULT_SIZE,
            mode: POINTS_DEFAULT_MODE,
        }),
        // TODO: need to separate, liberate plot update methods into webviz without redux
        plot: {
            views: [viewId],
        },
    };
    return snapshot;
}

export function generateVisualizationServiceUrl(baseUrl: string, params: TilesetEndpointParams) {
    return `${baseUrl}/visualization/orgs/${params.orgId}/workspaces/${params.workspaceId}/geoscience-object/${params.objectId}`;
}

function generateTileset3DLinesSnapshot(
    gooseObject: GeoscienceLineObject,
    params: TilesetEndpointParams,
): UpdateSnapshot | undefined {
    const { object_id: objectId, object } = gooseObject;

    const viewId = toSuffixUid(objectId, ViewClass.Lines);
    const elementId = toSuffixUid(objectId, ElementClass.Tileset3D);

    const { url: baseUrl } = getUrlConfig();
    const url = generateVisualizationServiceUrl(baseUrl, params);

    let attributesSnapshot = {};
    let firstColorData = '';
    if ('attributes' in object && object.attributes?.length) {
        const firstAttribute = object.attributes[0];
        if (firstAttribute && firstAttribute.values?.data) {
            firstColorData = toSuffixUid(
                `attribute_${firstAttribute.values.data}`,
                UID_SUFFIXES.DATA,
            );
        }
        attributesSnapshot = object.attributes.reduce(
            (accumulator: UpdateSnapshot, { values: { data }, attribute_type }) => ({
                ...accumulator,
                ...generateAttributesSnapshot(data, attribute_type),
            }),
            {},
        );
    }

    return {
        [elementId]: generateEntity(ElementClass.Tileset3D, {
            url,
        }),
        ...attributesSnapshot,
        [viewId]: generateEntity(ViewClass.Lines, {
            element: elementId,
            color: DEFAULT_FLAT_COLOR,
            width: LINE_DEFAULT_WIDTH,
            mode: LINE_DEFAULT_MODE,
            color_data: firstColorData,
        }),
        ...attributesSnapshot,

        // TODO: need to separate, liberate plot update methods into webviz without redux
        plot: {
            views: [viewId],
        },
    };
}
