import { OrgSelectMenu } from '@local/login/dist/components/orgSelector/OrgSelectMenu/OrgSelectMenu';
import {
    getAvailableEvoInstances,
    LICENSE_TOKEN_KEY,
} from '@local/login/dist/store/sessionStorageHelpers/entitlementsHelper/entitlementsHelper';
import uniqBy from 'lodash-es/uniqBy';
import { useParams } from 'react-router-dom';

import { useStyles } from './OrgSelector.styles';

export const OrgSelector = () => {
    const { classes } = useStyles();
    const evoInstances = getAvailableEvoInstances();
    const params = useParams();
    const currentOrgId = params.orgUuid;
    const organizations = uniqBy(
        evoInstances.map(({ org }) => org),
        (org) => org.display_name,
    );
    const options = organizations.map(({ display_name, id }) => ({
        label: display_name,
        key: id,
    }));

    function navigateToOrganization(selectedOrgUuid: string) {
        // Nothing to do if the user has selected their current org
        if (selectedOrgUuid === currentOrgId) {
            return null;
        }

        // We can let 401 retry logic take care of getting authentication tokens for the new
        // instance once the user has been navigated to the new page.
        sessionStorage.removeItem(LICENSE_TOKEN_KEY);

        // By setting the window location directly we ensure a full page refresh occurs and
        // state is cleared.
        window.location.href = `${window.location.origin}/${selectedOrgUuid}/workspaces`;
        return null;
    }

    // Do not show the org selector if the user only has access to a single org/instance.
    if (organizations.length === 1) {
        return null;
    }
    return (
        <div className={classes.orgSelector}>
            <OrgSelectMenu
                options={options}
                onSelected={(key: string) => navigateToOrganization(key)}
                selected={currentOrgId}
                variant="light"
                topSpacing={6}
                width={256}
            />
        </div>
    );
};
