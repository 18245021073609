import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
    orgSelector: {
        width: theme.spacing(32),
        alignContent: 'center',
        float: 'right',
        '& svg': {
            fill: '#000',
        },
    },
}));
