import { fonts } from '@local/web-design-system/dist/styles/fonts';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
    deleteButton: {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.grey[50],
        '&:hover': {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.primary.dark,
        },
        ...fonts.button,
        width: '100%',
    },
    buttonIconText: {
        paddingLeft: theme.spacing(2),
    },
}));
