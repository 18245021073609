import { RoleEnum } from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import { createContext, useContext } from 'react';

interface Context {
    workspaceUserRole?: RoleEnum;
}

export const PermissionsContext = createContext<Context>({
    workspaceUserRole: undefined,
});

export const usePermissionsContext = () => useContext(PermissionsContext);
