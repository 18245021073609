import { createAccessTokenBaseQuery } from '@local/login/dist/apiClients/identityClient/requests/AccessTokenApi';
import { createApi } from '@reduxjs/toolkit/query/react';

import { getUrlConfig } from './utils';

export const gooseClient = createApi({
    tagTypes: ['goose'],
    reducerPath: 'goose',
    baseQuery: createAccessTokenBaseQuery(getUrlConfig),
    endpoints: () => ({}),
});
