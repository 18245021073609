import { createAccessTokenBaseQuery } from '@local/login/dist/apiClients/identityClient/requests/AccessTokenApi';
import { createApi } from '@reduxjs/toolkit/query/react';

import { getUrlConfig } from './utils';

export const fileClient = createApi({
    reducerPath: 'file',
    baseQuery: createAccessTokenBaseQuery(() => getUrlConfig(false, true)),
    tagTypes: ['File', 'file'],
    endpoints: () => ({}),
});
