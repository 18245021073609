import { ErrorScreen } from '@local/svgs/dist/pageState/ErrorScreen';
import { NotFoundSvg } from '@local/svgs/dist/svg/NotFoundSvg';
import { EmptyState } from '@local/web-design-system/dist/components/EmptyState';
import { FieldDefinition, Order } from '@local/web-design-system/dist/components/GenericListing';
import { SortedList } from '@local/web-design-system/dist/components/PaginatedList';
import { Pagination } from '@local/web-design-system/dist/components/PaginationControls';
import { BlankTableLoadingSkeleton } from '@local/web-design-system/dist/components/TableLoadingSkeleton/BlankTableLoadingSkeleton';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Grid from '@mui/material/Grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useListObjectsFilteredQuery } from 'src/apiClients/enhancedGooseMiddleware';
import { ListedObject } from 'src/apiClients/GENERATED_gooseClientEndpoints';
import { useObjectFilterParams } from 'src/hooks/useObjectFilterParams';
import { useObjectSearchParams } from 'src/hooks/useObjectSearchParams';
import { useObjectSortParams } from 'src/hooks/useObjectSortParams';
import { usePagination } from 'src/hooks/usePagination';
import { workspaceContentModeSelector } from 'src/store/features/displaySettingSlice';
import { useAppSelector } from 'src/store/store';
import {
    NETWORK_ERROR_DESCR,
    NETWORK_ERROR_TITLE,
    WORKSPACE_CONTENT_NO_CONTENT,
    WORKSPACE_CONTENT_NO_CONTENT_DESC,
} from 'src/strings';
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'src/utils/pagination';

import { ContentOptions } from '../ContentOptions';
import { objectDefinition } from './FieldRowDefinitions';
import { ObjectRow } from './ObjectRow';
import { ListedObjectDisplay, processGooseContents } from './utils';
import { useStyles } from './WorkspaceContent.styles';

function SortedListItem(props: {
    object: ListedObjectDisplay;
    // The way that SortedList is implemented requires sortKey to be a property on all the children it sorts.
    // eslint-disable-next-line react/no-unused-prop-types
    sortKey: string;
    registerForSorting?: (sortFieldsFunc: () => ListedObjectDisplay) => void;
}) {
    const { object, registerForSorting = () => null } = props;
    registerForSorting(() => object);

    return <ObjectRow object={object} />;
}

interface ObjectListProps {
    objects: ListedObject[];
    isLoading: boolean;
    handleSort: (values: { key: string; order: Order }) => void;
    fields?: FieldDefinition<any>[];
}

export const ObjectList = ({
    isLoading,
    objects,
    handleSort,
    fields = objectDefinition,
}: ObjectListProps) => {
    const { classes } = useStyles();

    if (isLoading) {
        return <BlankTableLoadingSkeleton />;
    }

    if (!isLoading && !objects.length) {
        return (
            <EmptyState
                title={WORKSPACE_CONTENT_NO_CONTENT}
                message={WORKSPACE_CONTENT_NO_CONTENT_DESC}
                image={
                    <div className={classes.noSearchResultsImage}>
                        <NotFoundSvg />
                    </div>
                }
                classes={{ root: classes.noSearchResults }}
            />
        );
    }

    const processedObjects = processGooseContents(0, objects);

    return (
        <SortedList
            itemDef={fields}
            stickyHeader
            isEmpty={!processedObjects.length}
            classes={classes}
            onHeaderSortClickedEventHandler={handleSort}
        >
            {processedObjects.map((object: ListedObjectDisplay) => (
                <SortedListItem key={object.object_id} sortKey={object.object_id} object={object} />
            ))}
        </SortedList>
    );
};

export const WorkspaceContent = () => {
    const { classes } = useStyles();
    const { evouiAffinityFolders } = useFlags();
    const workspaceContentMode = useAppSelector(workspaceContentModeSelector);
    const showFilterDropdown = evouiAffinityFolders && workspaceContentMode === 'path';
    const {
        page,
        pageSize,
        handleNextPage,
        handlePreviousPage,
        handlePageSizeChange,
        setPaginationParams,
    } = usePagination();

    const params = useParams();
    const sortFromHook = useObjectSortParams();
    const { filters: filtersFromHook } = useObjectFilterParams();
    const filters = useMemo(
        () => (showFilterDropdown ? filtersFromHook : {}),
        [showFilterDropdown, filtersFromHook],
    );
    const { updateObjectSortParams, orderBy, order, key } = useMemo(
        () =>
            showFilterDropdown
                ? sortFromHook
                : {
                      updateObjectSortParams: () => {},
                      orderBy: undefined,
                      order: undefined,
                      key: undefined,
                  },
        [showFilterDropdown, sortFromHook],
    );

    const { objectName } = useObjectSearchParams();

    const fields = objectDefinition.map((field) =>
        field.key === key ? { ...field, defaultSortSettings: { order: order as Order } } : field,
    );

    const handleSort = (values: { key: string; order: Order }) => {
        if (showFilterDropdown) {
            if (order === values.order && key === values.key) {
                return;
            }

            updateObjectSortParams(values);
            setPaginationParams((currentParams) => ({ ...currentParams, page: 0 }));
        }
    };

    useEffect(() => {
        setPaginationParams((currentParams) => ({ ...currentParams, page: 0 }));
    }, [filters, objectName]);

    const {
        data: gooseData,
        isFetching,
        isError,
    } = useListObjectsFilteredQuery({
        orgId: getOrgUuidFromParams(params),
        workspaceId: getSelectedWorkspaceFromParams(params),
        ...filters,
        objectName,
        orderBy,
        offset: page * pageSize,
        limit: pageSize,
    });

    if (isError) {
        return <ErrorScreen msg={NETWORK_ERROR_TITLE} details={NETWORK_ERROR_DESCR} />;
    }

    return (
        <>
            <ContentOptions
                paginationControls={
                    <Grid item>
                        <Pagination
                            numberOfRows={gooseData?.total ?? 0}
                            rowsPerPage={pageSize}
                            page={page}
                            pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                            ariaLabelPrevious="Previous Page"
                            ariaLabelNext="Next Page"
                            handleChangeRowsPerPage={handlePageSizeChange}
                            handleBackButtonClick={handlePreviousPage}
                            handleNextButtonClick={handleNextPage}
                        />
                    </Grid>
                }
            />
            <div className={classes.table}>
                <Grid container direction="column" wrap="nowrap">
                    <ObjectList
                        objects={gooseData?.objects ?? []}
                        isLoading={isFetching}
                        handleSort={handleSort}
                        fields={fields}
                    />
                </Grid>
            </div>
        </>
    );
};
