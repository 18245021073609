import { ListCellComponentProps } from '@local/web-design-system/dist/components/GenericListing';
import FolderIcon from '@local/web-design-system/dist/icons/Files/FolderIcon';
import FolderOpenIcon from '@local/web-design-system/dist/icons/Files/FolderOpenIcon';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { SchemaIcon } from './SchemaIcon';
import { fileNameExtensionRemover } from './utils';

export const EmptyCell = () => <Typography variant="body2" />;

export const FolderCell = ({ item, itemKey }: ListCellComponentProps) => {
    const folderName = item[itemKey];
    return (
        <Grid direction="row" container style={{ paddingLeft: `${item.depth * 30}px` }}>
            {item.isOpen ? <FolderOpenIcon /> : <FolderIcon />}
            <Typography variant="body2" style={{ paddingLeft: '10px', lineHeight: '28px' }}>
                {folderName}
            </Typography>
        </Grid>
    );
};

export const FileCell = ({ item }: ListCellComponentProps) => {
    const { schema, name } = item;
    const Icon = SchemaIcon(schema);
    return (
        <Grid direction="row" container style={{ paddingLeft: `${item.depth * 30}px` }}>
            <Icon />
            <Typography variant="body2" style={{ paddingLeft: '10px', lineHeight: '28px' }}>
                {fileNameExtensionRemover(name)}
            </Typography>
        </Grid>
    );
};
