import { LinearProgressFaked } from '@local/web-design-system/dist/components/Progress/LinearProgressFaked';
import { PlusIcon } from '@local/web-design-system/dist/icons/Actions/PlusIcon';
import { useTrace } from '@local/web-design-system/dist/utils/trace';
import { useBaseXyz } from '@local/webviz/dist/context/useBaseXyz';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useLazyGetObjectByIdQuery } from '../../../../apiClients/GENERATED_gooseClientEndpoints';
import { SchemaIcon } from '../../../../pages/workspacePage/workspaceContent/SchemaIcon';
import { fileNameExtensionRemover } from '../../../../pages/workspacePage/workspaceContent/utils';
import { useAppDispatch } from '../../../../store/store';
import { visualizationSlice } from '../../../../store/visualization/visualizationSlice';
import { generateSnapshot } from '../../../context/snapshots/generateSnapshot';
import { useStyles } from './ArtifactListItemControl.styles';
import { ArtifactListItemControlProps } from './ArtifactListItemControl.types';

const APPROXIMATE_MAX_LOAD_TIME = 15000;

const {
    actions: { addToLoadedObjects },
} = visualizationSlice;

export function ArtifactListItemControl({
    objectListItem,
    selectedObjectId,
}: ArtifactListItemControlProps) {
    const { classes } = useStyles();
    const applyTrace = useTrace('artifact-list-item');
    const dispatch = useAppDispatch();
    const { setStateFromSnapshot, useXyzListener } = useBaseXyz();
    const [views, setViews] = useState<string[]>([]);
    const [GetObjectByIdTrigger, { data: object, isLoading, isSuccess, isError }] =
        useLazyGetObjectByIdQuery();
    useXyzListener('plot', 'views', (plotViews: string[]) => {
        setViews(plotViews);
    });

    let isObjectOnPlot = true;
    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);
    isObjectOnPlot = views.some((view) => !view.includes(objectListItem.object_id));
    const getObject = () => {
        GetObjectByIdTrigger({
            objectId: objectListItem.object_id,
            orgId,
            workspaceId,
        });
    };

    useEffect(() => {
        if (object) {
            const snapshot = generateSnapshot(object, {
                objectId: object.object_id,
                workspaceId,
                orgId,
            });
            if (!snapshot) {
                console.error(`Unsupported schema: ${object.object.schema}`);
                return;
            }
            setStateFromSnapshot(snapshot, {});
            dispatch(addToLoadedObjects(object.object));
        }
    }, [object]);

    const itemControlRef = useRef<HTMLDivElement>(null);
    const Icon = SchemaIcon(objectListItem.schema);

    return (
        <Grid
            item
            container
            className={classnames(classes.root, { [classes.errorContainer]: false })}
        >
            <Grid
                data-tag="channelList"
                className={classnames(classes.background, {
                    [classes.backgroundPending]: isLoading,
                    [classes.backgroundClear]: isSuccess || isError,
                    [classes.backgroundSelectedForLoading]:
                        objectListItem.object_id === selectedObjectId,
                })}
                ref={itemControlRef}
            />
            <Grid
                item
                container
                className={classes.artifactListItemControl}
                wrap="nowrap"
                alignItems="center"
                justifyContent="center"
            >
                <Grid
                    item
                    container
                    zeroMinWidth
                    className={classnames(classes.mainContainer, {
                        [classes.mainContainerOutlined]: false,
                    })}
                    alignItems="center"
                >
                    <Grid
                        item
                        container
                        xs
                        alignItems="stretch"
                        wrap="nowrap"
                        className={classes.nameContainer}
                        {...applyTrace(objectListItem.name)}
                    >
                        <Grid item className={classes.icon}>
                            <Icon />
                        </Grid>
                        <Typography variant="body2" color="inherit" className={classes.name}>
                            {fileNameExtensionRemover(objectListItem.name)}
                        </Typography>
                    </Grid>
                    {isLoading && (
                        <Grid className={classes.progressContainer}>
                            <LinearProgressFaked
                                isLoading={isLoading}
                                milliseconds={APPROXIMATE_MAX_LOAD_TIME}
                                hideWhenNotLoading
                                classes={{ root: classes.loadingProgressContainer }}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid item className={classes.loadButtonContainer}>
                    {isObjectOnPlot && (
                        <IconButton
                            onClick={() => getObject()}
                            className={classnames(classes.loadIconButton)}
                        >
                            <PlusIcon fontSize="inherit" />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
