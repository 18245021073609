import { ListCellComponentProps } from '@local/web-design-system/dist/components/GenericListing';
import Typography from '@mui/material/Typography';

export const extractSchemaName = (fullSchema: string | null) => {
    // This shouldn't happen, but is allowed by the OpenAPI schema.
    if (!fullSchema) {
        return '';
    }
    const matches = fullSchema.match(/([\w-]+).schema.json/);
    if (!matches) {
        return '';
    }

    return matches[1].replace(/-/g, ' ');
};

export const SchemaCell = ({ item, itemKey }: ListCellComponentProps) => {
    const fullSchema = item[itemKey];
    const schema = extractSchemaName(fullSchema);
    return <Typography variant="body2">{schema}</Typography>;
};
