import { azureMapSearchClient } from '@local/api-clients/dist/azureMapSearchApi';
import { XidApiClient } from '@local/api-clients/dist/azureMapTokenApi';
import { middleware as loginMiddleware, reducers as loginReducers } from '@local/login';
import { termsOfUseMiddleware, termsOfUseReducers } from '@local/terms-of-use';
import { middleware as manageMiddleware } from '@local/user-manage/dist';
import { manageClient } from '@local/user-manage/dist/apiClients/manageClient';
import { manageClientBentley } from '@local/user-manage/dist/apiClients/manageClient/manageClientBentley';
import { genericClient } from '@local/workspaces/dist/apiClients/genericApi/genericClient';
import { workspaceClient } from '@local/workspaces/dist/apiClients/workspaceClient';
import {
    AnyAction,
    EnhancedStore,
    Reducer,
    combineReducers,
    configureStore,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { fileClient } from 'src/apiClients/fileClient';
import { gooseClient } from 'src/apiClients/gooseClient';
import { displaySettingsSlice } from 'src/store/features/displaySettingSlice';

import { fileUploadStatus } from './slices/fileUploadStatus';
import { folderFilterSlice } from './slices/folderFilterSlice';
import { visualizationSlice } from './visualization/visualizationSlice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [displaySettingsSlice.name],
};

const combinedReducer = combineReducers({
    [workspaceClient.reducerPath]: workspaceClient.reducer,
    [fileClient.reducerPath]: fileClient.reducer,
    [gooseClient.reducerPath]: gooseClient.reducer,
    [genericClient.reducerPath]: genericClient.reducer,
    [manageClient.reducerPath]: manageClient.reducer,
    [manageClientBentley.reducerPath]: manageClientBentley.reducer,
    [XidApiClient.reducerPath]: XidApiClient.reducer,
    [azureMapSearchClient.reducerPath]: azureMapSearchClient.reducer,
    folderFilter: folderFilterSlice.reducer,
    [fileUploadStatus.name]: fileUploadStatus.reducer,
    [displaySettingsSlice.name]: displaySettingsSlice.reducer,
    [visualizationSlice.name]: visualizationSlice.reducer,
    ...loginReducers,
    ...termsOfUseReducers,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    if (action.type === 'store/reset') {
        return {} as RootState;
    }
    return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const createStore = () =>
    configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }).concat([
                workspaceClient.middleware,
                fileClient.middleware,
                gooseClient.middleware,
                genericClient.middleware,
                XidApiClient.middleware,
                azureMapSearchClient.middleware,
                ...manageMiddleware,
                ...loginMiddleware,
                ...termsOfUseMiddleware,
            ]),
    });

export const store: EnhancedStore = createStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

persistStore(store);

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
