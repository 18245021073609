import {
    DockableContainer,
    DockPosition,
} from '@local/web-design-system/dist/components/DockableContainer';
import { PropertiesHeader } from '@local/web-design-system/dist/components/PropertiesHeader';
import { MeasurementDialog } from '@local/webviz/dist/components/Measurement';
import { MEASUREMENT_TITLE } from '@local/webviz/dist/components/Measurement/MeasurementDialog.constants';
import {
    PROPERTIES_TITLE,
    PropertiesDialog,
} from '@local/webviz/dist/components/Properties/PropertiesDialog';
import { SelectionContents, SelectionPanel } from '@local/webviz/dist/components/Selection';
import { Settings, SETTINGS_TITLE } from '@local/webviz/dist/components/Settings';
import { SettingsVisualizationOverlays } from '@local/webviz/dist/components/Settings/Settings.types';
import {
    SLICE,
    DockableSliceDialog as SliceDialog,
} from '@local/webviz/dist/components/Slice/SliceDialog';
import { useBaseXyz } from '@local/webviz/dist/context/useBaseXyz';
import { useSelection } from '@local/webviz/dist/hooks/useSelection';

import { SchemaIcon } from 'src/pages/workspacePage/workspaceContent/SchemaIcon';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    getObjectNameByObjectId,
    getObjectSchemaByObjectId,
    loadedObjectById,
    orientationVisible,
    scalebarVisible,
    selectedObjectIds,
    visualizationSlice,
} from 'src/store/visualization/visualizationSlice';
import { extractSchema } from 'src/utils/extractSchema';

import { DockableDialogProps, DockedWindows, ObjectAttributeType } from './DockableDialogs.types';

const {
    actions: { updateOverlays },
} = visualizationSlice;

const DOCK_THRESHOLD = 25;
export function DockableSliceDialog({ onClose, defaultPosition }: Readonly<DockableDialogProps>) {
    return (
        <SliceDialog
            header={{ title: SLICE, onClose }}
            type={DockedWindows.Slice}
            dockPosition={DockPosition.UPPER}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
        />
    );
}

export function DockableMeasureDialog({ onClose, defaultPosition }: Readonly<DockableDialogProps>) {
    return (
        <DockableContainer
            header={{ title: MEASUREMENT_TITLE, onClose }}
            type={DockedWindows.Measure}
            dockPosition={DockPosition.LOWER}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
        >
            <MeasurementDialog />
        </DockableContainer>
    );
}

export function DockableSettingsDialog({
    onClose,
    defaultPosition,
}: Readonly<DockableDialogProps>) {
    const isOrientationChecked = useAppSelector(orientationVisible);
    const isScalebarChecked = useAppSelector(scalebarVisible);
    const dispatch = useAppDispatch();
    const updateOverlaysDispatch = (overlays: Partial<SettingsVisualizationOverlays>) =>
        dispatch(updateOverlays(overlays));
    const { getEntityState } = useBaseXyz();
    const plotState = getEntityState('plot');
    let numberOfObjects = 0;
    if (plotState && 'views' in plotState) {
        numberOfObjects = plotState.views.length;
    }

    return (
        <DockableContainer
            type={DockedWindows.Settings}
            header={{ title: SETTINGS_TITLE, onClose }}
            dockPosition={DockPosition.LOWER}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
        >
            <Settings
                isOrientationChecked={isOrientationChecked}
                isScalebarChecked={isScalebarChecked}
                numberOfObjects={numberOfObjects}
                updateOverlays={updateOverlaysDispatch}
            />
        </DockableContainer>
    );
}

export function DockablePropertiesDialog({
    onClose,
    defaultPosition,
}: Readonly<DockableDialogProps>) {
    const selectedIds = useAppSelector(selectedObjectIds);

    const selectedId = selectedIds ? selectedIds[0] : '';
    const selectedObjName = useAppSelector(getObjectNameByObjectId(selectedId));
    const selectedObjSchema = useAppSelector(getObjectSchemaByObjectId(selectedId));
    const selectedObjectJson = useAppSelector(loadedObjectById(selectedId));

    const multipleSelected = selectedIds.length > 1;
    const showHeaderAdornment = !multipleSelected && selectedObjName;

    const attributesDict = selectedObjectJson?.attributes?.reduce(
        (accumulator: ObjectAttributeType, { name, values }: ObjectAttributeType) => ({
            ...accumulator,
            [name]: { propertyId: `attribute_${values.data}` },
        }),
        {},
    );

    return (
        <DockableContainer
            header={{
                title: PROPERTIES_TITLE,
                headerAdornment: showHeaderAdornment && (
                    <PropertiesHeader
                        name={selectedObjName}
                        Icon={SchemaIcon(extractSchema(selectedObjSchema))}
                    />
                ),
                onClose,
            }}
            type={DockedWindows.Properties}
            dockPosition={DockPosition.LOWER}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
        >
            <PropertiesDialog
                selectedViewIds={selectedIds}
                attributes={attributesDict}
                showEmptyMessage={!selectedObjName}
            />
        </DockableContainer>
    );
}

export function DockableSelectionPanel() {
    const { getEntityState } = useBaseXyz();
    const { selectionState } = useSelection();

    const viewId = selectionState?.viewId ?? '';
    const viewState = getEntityState(viewId);

    const selectedObjName = useAppSelector(getObjectNameByObjectId(viewId));
    const selectedObjSchema = useAppSelector(getObjectSchemaByObjectId(viewId));

    // TODO readd below when ready: artifact === undefined ||
    if (!selectionState?.position || !viewState) {
        return null;
    }

    const Icon = selectedObjSchema ? SchemaIcon(extractSchema(selectedObjSchema)) : undefined;

    const stylePosition = {
        bottom: 16,
        left: 8,
    };

    // TODO add selection contents when ready
    return (
        <DockableContainer type={DockedWindows.SelectionPanel} stylePosition={stylePosition}>
            <SelectionPanel title={selectedObjName} Icon={Icon}>
                <SelectionContents selectionKeyValues={[]} />
            </SelectionPanel>
        </DockableContainer>
    );
}
