import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { trackError, trackUserAction } from '@local/metrics';
import { EvoService, ManageUser } from '@local/user-manage/dist/apiClients/entities';
import { useDeleteUserAllMutation } from '@local/user-manage/dist/apiClients/manageClient/enhancedUserManageMiddleware';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import { DeleteIcon } from '@local/web-design-system/dist/icons';
import {
    getHubForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';

import { Categories, UserAction } from 'src/metrics.types';
import {
    ADMIN_CANNOT_SELF_DELETE,
    DELETE,
    USER_DELETE_FAIL,
    USER_DELETE_SUCCESS,
} from 'src/strings';

import { useStyles } from './DeleteUserButton.styles';

export const DELETE_BUTTON_CACHE_KEY = 'deleteUserButton';

export function DeleteUserButton({
    user,
    disabled,
    currentEntitlements,
}: {
    user: ManageUser;
    disabled: boolean;
    currentEntitlements: EvoService[];
}) {
    const { classes } = useStyles();
    const { addMessage } = useMessagesContext();

    const params = useParams();
    const navigate = useNavigate();

    const [deleteAllUser] = useDeleteUserAllMutation({
        fixedCacheKey: DELETE_BUTTON_CACHE_KEY,
    });

    const removeFromAllGroups = async () => {
        try {
            await deleteAllUser({
                org_id: getOrgUuidFromParams(params),
                hub: getHubForCurrentOrg().code,
                email: user.email,
                entitlements: currentEntitlements as EvoService[],
            });
            trackUserAction(UserAction.USER_REMOVED, {
                category: Categories.EVO_USER_MANAGEMENT,
            });
            addMessage({
                message: USER_DELETE_SUCCESS,
                type: NotificationType.SUCCESS,
            });
            navigate('../');
        } catch {
            trackError('Error removing user from evo');
            addMessage({
                message: USER_DELETE_FAIL,
                type: NotificationType.ERROR,
            });
        }
    };

    return (
        <Grid item xs={6}>
            <Tooltip title={disabled ? ADMIN_CANNOT_SELF_DELETE : ''}>
                <span>
                    <Button
                        color="secondary"
                        variant="contained"
                        size="large"
                        className={classes.deleteButton}
                        disabled={disabled}
                        onClick={removeFromAllGroups}
                        automation-id="delete-user"
                    >
                        <DeleteIcon color="inherit" fontSize="small" />
                        <Typography
                            variant="body2"
                            color="inherit"
                            className={classes.buttonIconText}
                        >
                            {DELETE}
                        </Typography>
                    </Button>
                </span>
            </Tooltip>
        </Grid>
    );
}
