import { EvoService } from '@local/user-manage/dist';

export interface Product {
    displayName: string;
    service: EvoService;
}

export function getProducts(evouiDriverManagement: boolean, evouiVarifyManagement: boolean) {
    const result: Product[] = [
        {
            displayName: 'Blocksync',
            service: 'blockmodel',
        },
    ];
    if (evouiDriverManagement) {
        result.push({
            displayName: 'Driver',
            service: 'driver',
        });
    }
    if (evouiVarifyManagement) {
        result.push({
            displayName: 'Varify',
            service: 'varify',
        });
    }
    return result;
}

// Products that have an admin entitlement distinct from evo-admin
const adminProducts: EvoService[] = ['blockmodel'];

// filters available entitlements for those with their own admin entitlement
export function availableAdminEntitlements(availableProducts: EvoService[]) {
    return adminProducts.filter((adminProduct) => availableProducts.includes(adminProduct));
}
