import { Filter } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';

import { ListedObject } from './GENERATED_gooseClientEndpoints';

export const filterByDate = (object: ListedObject, folder: Filter) => {
    const comparisonDate = new Date();
    const days = parseInt(folder.comparison[0].slice(0, -1), 10);
    comparisonDate.setDate(comparisonDate.getDate() - days);
    if (folder.condition === 'greaterThan') {
        return new Date(object.created_at) > comparisonDate;
    }
    if (folder.condition === 'equals') {
        const objectDate = new Date(object.created_at);
        const objectDateString = `${objectDate.getDate()}-${objectDate.getMonth()}-${objectDate.getFullYear()}`;
        const comparisonDateString = `${comparisonDate.getDate()}-${comparisonDate.getMonth()}-${
            comparisonDate.getFullYear
        }`;
        return objectDateString === comparisonDateString;
    }
    if (folder.condition === 'lessThan') {
        return new Date(object.created_at) < comparisonDate;
    }
    return false;
};

export const filterBySchema = (object: ListedObject, folder: Filter) =>
    folder?.comparison?.filter((currentSchema) =>
        object.schema.includes(`${currentSchema}.schema.json`),
    ).length > 0;
