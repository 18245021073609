import { TitleRow } from '@local/content-area/dist/TitleRow/TitleRow';
import { BoundingBoxControls } from '@local/map-viewer/dist/controls/BoundingBoxControls';
import { Search } from '@local/map-viewer/dist/layers/Search';
import { MapViewer } from '@local/map-viewer/dist/MapViewer';
import type { LatLng } from '@local/map-viewer/dist/types';
import { Breadcrumb, BreadcrumbSegment } from '@local/web-design-system/dist/components/Breadcrumb';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import Grid from '@mui/material/Grid';
import { useState } from 'react';

import { discoveryBreadcrumb } from 'src/utils/breadcrumbs';

import { DISCOVERY_TITLE } from '../../strings';

export function DiscoverPage() {
    const [, setBoundingBox] = useState<LatLng[]>([]);

    setDocumentTitle('Discover');

    const segments: BreadcrumbSegment[] = [discoveryBreadcrumb];

    return (
        <Grid container>
            <Breadcrumb segments={segments} medium />
            <TitleRow title={DISCOVERY_TITLE} />
            <MapViewer>
                <Search />
                <BoundingBoxControls onChange={setBoundingBox} />
            </MapViewer>
        </Grid>
    );
}
