import { Filter } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';

import { ListedObject, ListObjectsResponse } from 'src/apiClients/GENERATED_gooseClientEndpoints';

import { filterByDate, filterBySchema } from './filters';

export const filterByProperty = (data: ListObjectsResponse, folderSchema: Filter[]) => {
    if (!folderSchema.length) {
        return [];
    }
    const returnProperFilter = (gooseObject: ListedObject, folder: Filter) => {
        if (folder.property === 'created_at') {
            return filterByDate(gooseObject as ListedObject, folder);
        }
        if (folder.property === 'schema') {
            return filterBySchema(gooseObject as ListedObject, folder);
        }
        return false;
    };
    const filteredData = data.objects.filter((gooseObject) =>
        folderSchema.every((folder) => returnProperFilter(gooseObject as ListedObject, folder)),
    );
    return filteredData;
};
