import { customGooseClientEndpoints } from './customGooseClientEndpoints';
import { gooseClientEndpoints } from './GENERATED_gooseClientEndpoints';

export const enhancedGooseClient = gooseClientEndpoints.enhanceEndpoints({
    addTagTypes: ['Object'],
    endpoints: {
        getObjectById: {
            providesTags: ['Object'],
        },
    },
});

export const enhancedCustomGooseClient = customGooseClientEndpoints.enhanceEndpoints({
    addTagTypes: ['Object'],
    endpoints: {
        getObjectsByFilter: {
            providesTags: ['Object'],
        },
        listObjectsFiltered: {
            providesTags: ['Object'],
        },
    },
});

export const { useGetObjectByIdQuery, useLazyGetObjectByIdQuery } = enhancedGooseClient;

export const {
    useGetObjectsByFilterQuery,
    useLazyGetObjectsByFilterQuery,
    useListObjectsFilteredQuery,
    useLazyListObjectsFilteredQuery,
} = enhancedCustomGooseClient;
