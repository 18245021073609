/* eslint-disable no-param-reassign */
import { Slice, createSelector, createSlice } from '@reduxjs/toolkit';

import { GeoscienceObject } from 'src/apiClients/GENERATED_gooseClientEndpoints';

import type { RootState } from '../store';
import {
    GeoscienceObjectPayload,
    SelectorReturnTypeBoolean,
    SelectorReturnTypeGeoscienceObject,
    SelectorReturnTypeGeoscienceObjects,
    SelectorReturnTypeString,
    SelectorReturnTypeStringArray,
    StringArrayPayload,
    VisualizationState,
    VisualizationStatePayload,
} from './visualizationSlice.types';

export const initialState: VisualizationState = {
    selectedObjectIds: [],
    loadedObjects: [],
    overlays: { scalebar: true, orientation: true },
};
const visualizationState = (state: RootState): VisualizationState =>
    state.visualization ?? initialState;

export const scalebarVisible: SelectorReturnTypeBoolean = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.overlays.scalebar,
);

export const orientationVisible: SelectorReturnTypeBoolean = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.overlays.orientation,
);

export const loadedObjects: SelectorReturnTypeGeoscienceObjects = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.loadedObjects,
);

export const loadedObjectById = (objectId: string): SelectorReturnTypeGeoscienceObject =>
    createSelector(visualizationState, (visualizationStateRoot) =>
        visualizationStateRoot.loadedObjects.find((item) => objectId.includes(item.uuid)),
    );

export const selectedObjectIds: SelectorReturnTypeStringArray = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.selectedObjectIds,
);

export const getObjectSchemaByObjectId = (objectId: string): SelectorReturnTypeString =>
    createSelector(loadedObjectById(objectId), (object) => object?.schema ?? '');

export const getObjectNameByObjectId = (objectId: string): SelectorReturnTypeString =>
    createSelector(loadedObjectById(objectId), (object) => object?.name ?? '');

export const visualizationSlice: Slice = createSlice({
    name: 'visualization',
    initialState,
    reducers: {
        updateSelection(state: VisualizationState, { payload }: StringArrayPayload) {
            state.selectedObjectIds = payload;
        },
        addToLoadedObjects(state: VisualizationState, { payload }: GeoscienceObjectPayload) {
            const foundIndex = state.loadedObjects.findIndex(
                (item: GeoscienceObject) => item.uuid === payload.uuid,
            );
            if (foundIndex === -1) {
                state.loadedObjects.push(payload);
            }
        },
        updateOverlays(state: VisualizationState, { payload }: VisualizationStatePayload) {
            state.overlays = { ...state.overlays, ...payload };
        },
    },
});
