import { getConfig } from '@local/app-config';
import { getAdminFromToken } from '@local/login';
import { BentleyGenericUserList } from '@local/user-manage/dist/components/GenericUserList/BentleyGenericUserList';
import { GenericUserList } from '@local/user-manage/dist/components/GenericUserList/GenericUserList';
import { BreadcrumbSegment } from '@local/web-design-system/dist/components/Breadcrumb';
import { AddUserIcon } from '@local/web-design-system/dist/icons/User';
import { Restricted } from '@local/workspaces/dist/components/Restricted/Restricted';
import { ActionButton } from '@local/workspaces/dist/components/titleRow/buttons/ActionButton';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate, useParams } from 'react-router-dom';

import { PageContent } from 'src/components/pageContent/PageContent';
import { usersBreadcrumb } from 'src/utils/breadcrumbs';

import { INVITE_USERS, RESTRICTED_NO_PERMISSION_USERS, USERS_TITLE } from '../../strings';
import { USER_INVITE_PAGE, USER_MANAGEMENT_PAGE } from '../../urls';

export function ListUsersPage() {
    setDocumentTitle('Users');
    const navigate = useNavigate();
    const params = useParams();
    const isAdminOrBentleyEnabled = getAdminFromToken() || getConfig().bentleyIdEnabled;

    const { evouiHideLicenseInfo } = useFlags();

    if (!isAdminOrBentleyEnabled) {
        return <Restricted message={RESTRICTED_NO_PERMISSION_USERS} />;
    }
    const selectUser = (userUuid: string) => {
        navigate(`${userUuid}`);
    };
    const segments: BreadcrumbSegment[] = [usersBreadcrumb];

    return (
        <PageContent
            breadcrumbSegments={segments}
            pageTitle={USERS_TITLE}
            titleActions={
                !evouiHideLicenseInfo ? (
                    <ActionButton
                        text={INVITE_USERS}
                        destination={`/${params.orgUuid}/${USER_MANAGEMENT_PAGE}/${USER_INVITE_PAGE}`}
                    >
                        <AddUserIcon fontSize="small" />
                    </ActionButton>
                ) : (
                    <> </>
                )
            }
        >
            {getConfig().bentleyIdEnabled ? (
                <BentleyGenericUserList service="evo" rowClickHandler={selectUser} addMessages />
            ) : (
                <GenericUserList service="evo" rowClickHandler={selectUser} addMessages />
            )}
        </PageContent>
    );
}
