import { common } from '@local/web-design-system/dist/styles/common';
import { fonts } from '@local/web-design-system/dist/styles/fonts';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const styles = (theme: Theme) =>
    ({
        root: {
            paddingTop: theme.spacing(),
            paddingBottom: 0,
            height: '100%',
            overflowY: 'auto',
        },
        panelHeader: {
            fontSize: '13px',
            textTransform: 'uppercase',
            color: theme.palette.grey[700],
            padding: theme.spacing(1, 2, 0.75),
            fontWeight: fonts.weights.medium,
        },
        artifactsList: {
            ...common(theme).thinScrollBar,
            padding: theme.spacing(0, 2),
            overflowX: 'hidden',
        },
        emptyStateMessageContainer: {
            height: '60%',
        },
        emptyStateMessage: {
            fontSize: '14px',
            fontWeight: fonts.weights.regular,
        },
    }) as const;

export const useStyles = makeStyles()(styles);
