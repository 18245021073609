import { getAdminFromToken, useGetUserDetailsQuery } from '@local/login';
import { Messages } from '@local/messages/dist/Messages';
import { ErrorScreen } from '@local/svgs/dist/pageState/ErrorScreen';
import {
    ALL_ENTITLEMENTS,
    EvoService,
    ManageUser,
} from '@local/user-manage/dist/apiClients/entities';
import {
    useDeleteUserAllMutation,
    useGetAdminsQuery,
    useGetEntitlementsForUserQuery,
    useUpdateUserMutation,
} from '@local/user-manage/dist/apiClients/manageClient/enhancedUserManageMiddleware';
import { ProductSelections } from '@local/user-manage/dist/apiClients/manageClient/groupedManageEndpoints';
import { Skeleton } from '@local/web-design-system/dist/components/Skeleton';
import {
    getHubForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { Restricted } from '@local/workspaces/dist/components/Restricted/Restricted';
import { BackButton } from '@local/workspaces/dist/components/titleRow/buttons/BackButton';
import { USERS_PAGE } from '@local/workspaces/dist/urls';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    DELETE_BUTTON_CACHE_KEY,
    DeleteUserButton,
} from 'src/pages/editUserPage/deleteUserButton/DeleteUserButton';
import {
    SAVE_BUTTON_CACHE_KEY,
    SaveUserButton,
} from 'src/pages/editUserPage/saveUserButton/SaveUserButton';
import {
    ADMIN_CANNOT_SELF_DELETE,
    ADMIN_TOOLTIP,
    BACK_TO_USERS,
    NETWORK_ERROR_DESCR,
    NETWORK_ERROR_TITLE,
    NOT_FOUND_MESSAGE,
    RESTRICTED_NO_PERMISSION_USERS,
    USER_ADMIN,
    USER_NOT_FOUND_TITLE,
} from 'src/strings';

import { PageContent } from '../../components/pageContent/PageContent';
import { UserContentContainer } from '../../components/userContentContainer/UserContentContainer';
import { USER_MANAGEMENT_PAGE } from '../../urls';
import { getProducts, availableAdminEntitlements } from '../inviteUsersPage/products';
import { GenericNotFoundPage } from '../notFoundPage/GenericNotFoundPage';
import { useStyles } from './EditUser.styles';
import { UsersWorkspaces } from './workspacePermissions/UsersWorkspaces';

export function EditUserPage() {
    setDocumentTitle('Edit User');
    const { classes } = useStyles();
    const params = useParams();
    const isAdmin = getAdminFromToken();
    const { data: evoAdminList } = useGetAdminsQuery({
        org_id: getOrgUuidFromParams(params),
        hub: getHubForCurrentOrg().code,
        service: 'evo',
    });
    const {
        evouiVarifyManagement,
        evouiDriverManagement,
        evouiMoreUserManagement,
        evouiHideLicenseInfo,
    } = useFlags();

    const [userIsAdmin, setAdmin] = useState(false);
    const [productSelections, setProductSelections] = useState<ProductSelections>({});

    const [, { isLoading: saveIsLoading }] = useUpdateUserMutation({
        fixedCacheKey: SAVE_BUTTON_CACHE_KEY,
    });
    const [, { isLoading: deleteIsLoading }] = useDeleteUserAllMutation({
        fixedCacheKey: DELETE_BUTTON_CACHE_KEY,
    });

    useEffect(() => {
        const userIsAdminInitial = !!evoAdminList?.admins.find(
            (user: ManageUser) => user.id === params.userUuid,
        );

        setAdmin(userIsAdminInitial);
    }, [evoAdminList]);

    const { data, isLoading, isError } = useGetEntitlementsForUserQuery({
        org_id: getOrgUuidFromParams(params),
        hub: getHubForCurrentOrg().code,
        entitlements: ALL_ENTITLEMENTS,
        user_uuid: params.userUuid as string,
    });
    // need this to prevent user from deleting/ un-admin-ing themselves
    const {
        data: workingUser,
        isLoading: workingUserLoading,
        isError: workingUserError,
    } = useGetUserDetailsQuery();

    useEffect(() => {
        if (data) {
            const initialSelections: ProductSelections = {};
            data.currentEntitlements.forEach((entitlement: EvoService) => {
                initialSelections[entitlement] = true;
            });
            setProductSelections(initialSelections);
        }
    }, [data]);

    if (!isAdmin) {
        return <Restricted message={RESTRICTED_NO_PERMISSION_USERS} />;
    }

    if (isLoading || workingUserLoading) {
        return (
            <PageContent
                pageContentPadding={false}
                pageTitle=""
                pageTitleLoading
                breadcrumbSegments={[]}
                breadcrumbsLoading
                titleActions={
                    <BackButton
                        text={BACK_TO_USERS}
                        destination={`/${params.orgUuid}/${USER_MANAGEMENT_PAGE}`}
                    />
                }
            >
                <UserContentContainer isLoading columnContent="" />
            </PageContent>
        );
    }

    if (isError || workingUserError) {
        return <ErrorScreen msg={NETWORK_ERROR_TITLE} details={NETWORK_ERROR_DESCR} />;
    }

    if (!data || !data?.userDetails) {
        return <GenericNotFoundPage title={USER_NOT_FOUND_TITLE} message={NOT_FOUND_MESSAGE} />;
    }

    const editingSelf = workingUser?.sub === params.userUuid;
    const availableProducts = getProducts(evouiDriverManagement, evouiVarifyManagement)
        .filter(
            (product) =>
                data.availableEntitlements.includes(product.service) ||
                data.currentEntitlements.includes(product.service),
        )
        .sort((a, b) => a.displayName.localeCompare(b.displayName));

    // If a customer only has the Evo entitlement, and there are no additional entitlements available, we shouldn't
    // show the entitlement options.
    const shouldShowProductSelect =
        data.availableEntitlements.length > 0 || data.currentEntitlements.length > 1;

    return (
        <PageContent
            pageContentPadding={false}
            pageTitle={`${data.userDetails.given_name} ${data.userDetails.family_name}`}
            breadcrumbSegments={[
                { name: 'Users', path: `/${params.orgUuid}/${USERS_PAGE}` },
                { name: `${data.userDetails.given_name} ${data.userDetails.family_name}` },
            ]}
            titleActions={
                <BackButton
                    text={BACK_TO_USERS}
                    destination={`/${params.orgUuid}/${USER_MANAGEMENT_PAGE}`}
                />
            }
        >
            <UserContentContainer
                columnContent={
                    <>
                        <Grid item xs={12}>
                            <Typography color="primary" variant="subtitle2">
                                {`${data.userDetails.given_name} ${data.userDetails.family_name}`}
                            </Typography>
                            <Typography color="textSecondary" variant="subtitle2">
                                {data.userDetails.email}
                            </Typography>
                        </Grid>
                        {!evouiHideLicenseInfo && (
                            <>
                                <Divider className={classes.divider} />
                                <Grid item xs={12}>
                                    <Typography variant="h4">Roles</Typography>
                                    <Tooltip
                                        title={
                                            editingSelf ? ADMIN_CANNOT_SELF_DELETE : ADMIN_TOOLTIP
                                        }
                                        placement="right"
                                    >
                                        <FormControlLabel
                                            label={
                                                <Typography
                                                    variant="body2"
                                                    className={classes.checkboxLabelText}
                                                >
                                                    {USER_ADMIN}
                                                </Typography>
                                            }
                                            className={classes.checkboxLabel}
                                            control={
                                                <Checkbox
                                                    checked={userIsAdmin}
                                                    onChange={(_event, checked) =>
                                                        setAdmin(checked)
                                                    }
                                                    value="checkedB"
                                                    color="primary"
                                                    disabled={editingSelf}
                                                    className={classes.productCheckbox}
                                                />
                                            }
                                            automation-id="form-label-admin"
                                        />
                                    </Tooltip>
                                </Grid>
                                {!shouldShowProductSelect ? null : (
                                    <Grid
                                        item
                                        container
                                        direction="column"
                                        xs={12}
                                        className={classes.productList}
                                    >
                                        <>
                                            <Typography variant="h4">Products</Typography>
                                            <FormControlLabel
                                                // adding Evo checkbox which should always be on and non interactive
                                                label={
                                                    <Typography
                                                        variant="body2"
                                                        className={classes.checkboxLabelText}
                                                    >
                                                        Evo
                                                    </Typography>
                                                }
                                                className={classes.checkboxLabel}
                                                control={
                                                    <Checkbox
                                                        value="checkedB"
                                                        color="primary"
                                                        className={classes.productCheckbox}
                                                        checked
                                                        disabled
                                                    />
                                                }
                                                automation-id="evo-product-checkbox"
                                            />
                                            {availableProducts.map((product) => (
                                                <FormControlLabel
                                                    key={product.service}
                                                    className={classes.checkboxLabel}
                                                    control={
                                                        <div>
                                                            <Checkbox
                                                                checked={
                                                                    productSelections[
                                                                        product.service
                                                                    ] ?? false
                                                                }
                                                                onChange={(_event, checked) =>
                                                                    setProductSelections(
                                                                        (prevState) => ({
                                                                            ...prevState,
                                                                            [product.service]:
                                                                                checked,
                                                                        }),
                                                                    )
                                                                }
                                                                className={classes.productCheckbox}
                                                                value={product.service}
                                                                color="primary"
                                                                automation-id={`${product.service}-checkbox`}
                                                            />
                                                        </div>
                                                    }
                                                    label={
                                                        <Typography
                                                            variant="body2"
                                                            className={classes.checkboxLabelText}
                                                        >
                                                            {product.displayName}
                                                        </Typography>
                                                    }
                                                />
                                            ))}
                                        </>
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    xs={12}
                                    direction="column"
                                    container
                                    className={classes.buttonsWrapper}
                                >
                                    {saveIsLoading || deleteIsLoading ? (
                                        <Skeleton variant="rectangle" height="44px" />
                                    ) : (
                                        <>
                                            <DeleteUserButton
                                                user={data.userDetails}
                                                disabled={editingSelf}
                                                currentEntitlements={data.currentEntitlements}
                                            />
                                            <SaveUserButton
                                                isAdmin={userIsAdmin}
                                                user={data.userDetails}
                                                productSelections={productSelections}
                                                adminProducts={availableAdminEntitlements(
                                                    availableProducts.map((value) => value.service),
                                                )}
                                            />
                                        </>
                                    )}
                                </Grid>
                            </>
                        )}
                        <Messages queue="edit-user" className={classes.messageQueue} />
                    </>
                }
                panelContent={evouiMoreUserManagement && <UsersWorkspaces />}
            />
        </PageContent>
    );
}
