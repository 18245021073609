import { createContext } from 'react';

import { ListObjectsResponse } from 'src/apiClients/GENERATED_gooseClientEndpoints';

export type GooseList = {
    data: ListObjectsResponse;
    isFetching: boolean;
    isError: boolean;
    isSuccess: boolean;
};

export const GooseListInitial: GooseList = {
    data: {
        links: {
            next: undefined,
            prev: undefined,
        },
        objects: [],
    },
    isFetching: false,
    isError: false,
    isSuccess: false,
};

export const GooseListContext = createContext<GooseList>(GooseListInitial);
