import { RoleEnum } from '@local/workspaces/src/apiClients/GENERATED_workspaceClientEndpoints';

/**
 * Returns true if the current user role is equal to or higher than the requested role.
 */
export const hasRoleOrHigher = (currentUserRole: RoleEnum, role: RoleEnum) => {
    switch (currentUserRole) {
        case 'owner':
            return true;

        case 'editor':
            return ['viewer', 'editor'].includes(role);

        case 'viewer':
            return role === 'viewer';

        default:
            return false;
    }
};
