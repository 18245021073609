import { ListItem } from '@local/web-design-system/dist/components/GenericListing';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { Folder } from '@local/workspaces/src/apiClients/workspaceClientEndpoints';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useLazyGetObjectsByFilterQuery } from 'src/apiClients/enhancedGooseMiddleware';
import { usePersistedState } from 'src/hooks/usePersistedState';
import { AffinityFolderRowActions } from 'src/utils/affinityFolderRowActions';

import { EmptyFolderRow } from '../workspaceContent/EmptyFolderRow';
import { folderRowDefinition } from '../workspaceContent/FieldRowDefinitions';
import { LoadingRow } from '../workspaceContent/LoadingRow';
import { ObjectRow } from '../workspaceContent/ObjectRow';
import { ListedObjectDisplay } from '../workspaceContent/utils';
import { useStyles } from './AffinityFoldersContent.styles';

export function AffinityFolderRow({ folder, depth = 0 }: { folder: Folder; depth?: number }) {
    const params = useParams();
    const { classes } = useStyles();
    const [, setFolderID] = usePersistedState('folderID');
    const [isOpen, setIsOpen] = useState(false);
    const [fetchObjects, { data, isLoading }] = useLazyGetObjectsByFilterQuery();

    const closeFolder = () => {
        localStorage.removeItem(folder?.id);
        setIsOpen(false);
    };

    const openFolder = () => {
        setFolderID(folder?.id);
        localStorage.setItem(folder?.id, 'open');
        setIsOpen(true);
        fetchObjects({
            orgId: getOrgUuidFromParams(params),
            workspaceId: getSelectedWorkspaceFromParams(params),
            folderSchema: folder.filters ?? [],
        });
    };

    useEffect(() => {
        if (localStorage.getItem(folder.id)) {
            setIsOpen(true);
            fetchObjects({
                orgId: getOrgUuidFromParams(params),
                workspaceId: getSelectedWorkspaceFromParams(params),
                folderSchema: folder.filters ?? [],
            });
        }
    }, []);

    if (!isOpen) {
        return (
            <ListItem
                key={`${folder.name}-closed`}
                item={{ ...folder, depth }}
                fields={folderRowDefinition}
                clickHandler={openFolder}
                actions={<AffinityFolderRowActions folderId={`${folder.id}`} />}
                classes={{ root: classes.affinityFolderRowOverrides }}
            />
        );
    }

    const componentLoader = () => {
        if (isLoading) {
            return <LoadingRow key={`${folder.name}-loading`} />;
        }
        if (!data?.length && !folder.subFolders.length) {
            return <EmptyFolderRow depth={depth} />;
        }
        return [
            ...folder.subFolders.map((subFolder: Folder) => (
                <AffinityFolderRow key={subFolder.name} folder={subFolder} depth={depth + 1} />
            )),
            ...data.map((object: ListedObjectDisplay) => (
                <ObjectRow
                    key={object.object_id}
                    object={{ ...object, depth: depth + 1 } as ListedObjectDisplay}
                    displayEmptyActions
                />
            )),
        ];
    };
    return (
        <>
            <ListItem
                key={`${folder.name}-open`}
                item={{ ...folder, depth, isOpen }}
                fields={folderRowDefinition}
                clickHandler={closeFolder}
                actions={<AffinityFolderRowActions folderId={`${folder.id}`} />}
                classes={{ root: classes.affinityFolderRowOverrides }}
            />
            {componentLoader()}
        </>
    );
}
