import { fonts } from '@local/web-design-system/dist/styles/fonts';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
    controlButton: {
        color: theme.palette.primary.contrastText,
        ...fonts.button,
        width: '100%',
    },
    buttonIconText: {
        paddingLeft: theme.spacing(2),
    },
}));
